<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <!-- <loading-spinner
            type="controller"
            :duration="1000"
            :size="40"
            color="#4469A8"
            :loading="loading"
          /> -->
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="admin.edit.title" v-if="isEditing"></app-i18n>
            <app-i18n code="admin.new.title" v-else></app-i18n>
          </h2>
          <section class="relative">
            <!-- <loading-spinner type="controller" :duration="1000" :size="40" color="#4469A8" :loading="findLoading" /> -->
            <!-- <LoadingIcon icon="grid" color="#2D3748" style="margin: 0 4px;" /> -->
            <div
              style="width: 100%; height: 80vh"
              class="flex justify-center"
              v-if="loading || findLoading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <div v-if="model">
              <div class="grid grid-cols-12 mt-5">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2 col-span-12"
                >
                  {{ i18n('iam.fields.firstName') }}
                </h4>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2 required relative"
                >
                  <input
                    id="crud-form-1"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.firstName"
                    :placeholder="i18n('iam.placeholder.firstName')"
                    required
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2 col-span-12"
                >
                  {{ i18n('iam.fields.lastName') }}
                </h4>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2 required relative"
                >
                  <input
                    id="crud-form-1"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.lastName"
                    :placeholder="i18n('iam.placeholder.lastName')"
                    required
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2 col-span-12"
                >
                  {{ i18n('iam.fields.email') }}
                </h4>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2 required relative"
                >
                  <input
                    id="crud-form-1"
                    type="email"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.email"
                    :disabled="isEditing"
                    :placeholder="i18n('iam.placeholder.email')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2 col-span-12"
                >
                  {{ i18n('iam.fields.phoneNumber') }}
                </h4>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2 required relative"
                  style="dir: ltr"
                >
                  <vue-tel-input
                    v-model="model.phoneNumber"
                    mode="international"
                    validCharactersOnly
                    :inputOptions="isRTL ? optionsAr : options"
                    required
                  ></vue-tel-input>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2 col-span-12"
                >
                  {{ i18n('iam.fields.roles') }}
                </h4>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2 required relative"
                  style="dir: ltr"
                >
                  <TomSelect
                    v-model="model.roles"
                    :options="{
                      placeholder: 'Select Role'
                    }"
                    class="w-full"
                    multiple
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :direction="isRTL ? 'rtl' : 'ltr'"
                  >
                    <option
                      :value="role.value"
                      v-for="role of roles"
                      :key="role.id"
                    >
                      {{ role.label }} -
                      <span class="text-sm text-gray-500">
                        {{ role.description }}
                      </span>
                    </option>
                  </TomSelect>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5" v-if="!isEditing">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2 col-span-12"
                >
                  {{ i18n('iam.fields.password') }}
                </h4>
                <div
                  class="sm:col-span-6 lg:col-span-4 col-span-12 lg:mt-0 mt-2 required relative"
                >
                  <input
                    id="crud-form-1"
                    type="text"
                    disabled
                    class="form-control w-full dark:placeholder-white"
                    v-model="model.password"
                  />
                </div>
                <div
                  class="items-center flex lg:col-span-2 col-span-6 mt-3 md:mt-0"
                >
                  <ShuffleIcon
                    class="cursor-pointer mx-3"
                    @click="generatePassword()"
                  />
                  <CopyIcon class="cursor-pointer" @click="copyPassword()" />
                </div>
              </div>
              <!-- END -->
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-31 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading || loading"
                  :loading="saveLoading"
                  @click="doSubmit()"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="btn bg-theme-32 text-black w-24 ml-3 mr-3 cursor-pointer"
                  @click="doCancel()"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </button>
              </div>
            </div>
          </section>
        </div>
        <div></div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import { FormSchema } from '@/shared/form/form-schema'
import { UserModel } from '@/store/user/user-model'

const { fields } = UserModel
const formSchema = new FormSchema([
  fields.id,
  fields.email,
  fields.password,
  fields.firstName,
  fields.lastName,
  fields.phoneNumber,
  fields.roles
  // fields.pagesAccess,
  // fields.avatar,
  // fields.lang
])

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false
    }
  },
  setup() {
    const errorMessage = ref('')
    return {
      errorMessage
    }
  },
  data() {
    return {
      model: null,
      rules: formSchema.rules(),
      options: { placeholder: 'Enter Your Phone Number' },
      optionsAr: { placeholder: 'ادخل رقم تليفونك' }
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'user/form/record',
      findLoading: 'user/form/findLoading',
      saveLoading: 'user/form/saveLoading',
      loading: 'user/form/loading',
      roles: 'user/form/roles'
    }),
    isEditing() {
      return !!this.id
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.notifications')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.notifications')
    }
  },
  async mounted() {
    await this.listRoles()
    if (this.isEditing) {
      await this.doFind({ id: this.id, type: 'admin' })
    } else {
      this.doNew()
    }

    this.model = formSchema.initialValues(this.record || {})
    if (!this.isEditing) this.generatePassword()
  },
  methods: {
    ...mapActions({
      doNew: 'user/form/doNew',
      doFind: 'user/form/doFind',
      doCreate: 'user/form/doAdd',
      doUpdate: 'user/form/doUpdate',
      listRoles: 'user/form/listRoles'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    generatePassword() {
      var generator = require('generate-password')
      this.model.password = generator.generate({
        length: 10,
        numbers: true
      })
    },
    copyPassword() {
      navigator.clipboard.writeText(this.model.password)
      Message.success(this.i18n('common.clipboard'))
    },
    isFormValid() {
      for (var key in this.model) {
        var value = this.model[key]
        for (var groupKey in value) {
          var value2 = value[groupKey]
          if (value2.length == 0) {
            this.errorMessage = 'notifications.create.popup.emptyFields'
            return false
          }
        }
      }
      const { phoneNumber, firstName, lastName, roles } = this.model
      if (!phoneNumber || !firstName || !lastName || roles.length === 0) {
        this.errorMessage = this.isRTL
          ? 'يرجى إدخال جميع الخانات'
          : 'Please enter all fields'
        return false
      }
      if (!this.validEmail(this.model.email)) {
        this.errorMessage = this.isRTL
          ? 'يرجى إدخال البريد الإلكتروني الصحيح'
          : 'Please enter a valid e-mail'
        return false
      }
      return true
    },
    validEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    doCancel() {
      this.$router.back()
    },
    doReset() {
      this.model = formSchema.initialValues(this.record)
    },
    async doSubmit() {
      const { id, ...values } = formSchema.cast(this.model)
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      // this.model.lang = this.language
      // const { id, ...values } = formSchema.cast(this.model)
      if (this.isEditing) {
        const roles = this.record.roles
        await this.doUpdate({
          id,
          roles,
          type: 'admin',
          ...values
        })
      } else {
        await this.doCreate({ type: 'admin', values })
      }
    }
  }
})
</script>
<style>
:deep(.tom-select .ts-input .item) {
  line-height: 15px !important;
}
</style>
